import React from 'react';
import { Download, Music, Video } from 'lucide-react';

interface DownloadButtonProps {
  onClick: () => void;
  label: string;
  isLoading?: boolean;
  disabled?: boolean;
  progress?: number;
  format: 'mp3' | 'mp4';
  quality: string;
}

export function DownloadButton({ 
  onClick, 
  label, 
  isLoading = false, 
  disabled = false,
  progress,
  format,
  quality
}: DownloadButtonProps) {
  const getIcon = () => {
    if (isLoading) return null;
    return format === 'mp3' ? <Music size={18} /> : <Video size={18} />;
  };

  const getQualityBadge = () => {
    const isHD = quality === '1080p' || quality === '720p';
    const isHighQuality = quality === '320 kbps' || quality === '256 kbps';
    
    return (
      <span className={`
        absolute -top-2 -right-2 px-2 py-0.5 text-xs font-semibold rounded-full
        ${format === 'mp3' 
          ? isHighQuality 
            ? 'bg-emerald-500 text-white' 
            : 'bg-blue-500 text-white'
          : isHD 
            ? 'bg-orange-500 text-white' 
            : 'bg-blue-500 text-white'
        }
      `}>
        {quality}
      </span>
    );
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        group relative w-full flex items-center justify-center gap-2 px-6 py-3 
        rounded-xl font-medium shadow-sm transition-all duration-300
        ${disabled || isLoading
          ? 'bg-gray-100 cursor-not-allowed'
          : format === 'mp3'
            ? 'bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700 text-white hover:shadow-lg hover:-translate-y-0.5'
            : 'bg-gradient-to-r from-orange-500 to-pink-600 hover:from-orange-600 hover:to-pink-700 text-white hover:shadow-lg hover:-translate-y-0.5'
        }
      `}
    >
      {getQualityBadge()}
      
      <span className="flex items-center gap-2">
        {isLoading ? (
          <div className="animate-spin w-5 h-5 border-3 border-white border-t-transparent rounded-full" />
        ) : (
          getIcon()
        )}
        <span className="font-medium">
          {isLoading 
            ? progress 
              ? `Converting... ${Math.round(progress)}%`
              : 'Converting...' 
            : label}
        </span>
      </span>
      
      {/* Progress bar */}
      {isLoading && typeof progress === 'number' && (
        <div className="absolute bottom-0 left-0 w-full h-1.5 bg-black/10 rounded-b-xl overflow-hidden">
          <div 
            className={`
              h-full transition-all duration-300
              ${format === 'mp3' 
                ? 'bg-emerald-300' 
                : 'bg-orange-300'
              }
            `}
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </button>
  );
}