export const API_CONFIG = {
  baseURL: 'https://api.yt2be.com/yt2api.php',
  timeout: 60000, // 60 seconds timeout
  retryAttempts: 3,
  retryDelay: 1000,
  pollInterval: 2000
} as const;

export const FORMATS = {
  mp3: ['64', '128', '192', '256', '320'],
  mp4: ['360', '480', '720', '1080']
} as const;

export const DOWNLOAD_CONFIG = {
  method: 'GET',
  target: '_blank',
  attributes: {
    novalidate: ''
  }
} as const;