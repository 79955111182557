import { FORMATS } from '../config/constants';

export function validateVideoFormat(format: string, quality: string): boolean {
  const normalizedFormat = format.toLowerCase();
  const normalizedQuality = normalizedFormat === 'mp3' 
    ? quality.replace(' kbps', '') 
    : quality.replace('p', '');

  if (!FORMATS[normalizedFormat as keyof typeof FORMATS]) {
    return false;
  }

  return FORMATS[normalizedFormat as keyof typeof FORMATS]
    .includes(normalizedQuality);
}

export function validateVideoId(id: string): boolean {
  return /^[a-zA-Z0-9_-]{11}$/.test(id);
}