import React, { useState } from 'react';
import { Search } from 'lucide-react';

export function SearchPage() {
  const [url, setUrl] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (url) {
      // Double encode the URL to handle special characters properly
      const encodedUrl = encodeURIComponent(encodeURIComponent(url));
      window.location.href = `/widget/${encodedUrl}`;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex flex-col items-center justify-center p-4">
      <div className="max-w-2xl w-full">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            YouTube Downloader
          </h1>
          <p className="text-lg text-gray-600">
            Enter a YouTube URL to download videos and audio
          </p>
        </div>

        <form onSubmit={handleSubmit} className="w-full space-y-4">
          <div className="relative">
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Paste YouTube URL here (video or shorts)"
              className="w-full px-4 py-3 pr-12 rounded-lg border border-gray-300 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 outline-none transition-all"
            />
            <Search className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <button
            type="submit"
            disabled={!url}
            className={`w-full py-3 rounded-lg font-medium transition-colors ${
              url
                ? 'bg-purple-600 hover:bg-purple-700 text-white'
                : 'bg-gray-300 cursor-not-allowed text-gray-500'
            }`}
          >
            Download
          </button>
        </form>

        <div className="mt-8 text-center">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Supported Formats</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-medium text-gray-800 mb-2">Audio</h3>
              <ul className="text-gray-600 space-y-1">
                <li>MP3 - High Quality (320 kbps)</li>
                <li>MP3 - Medium Quality (256 kbps)</li>
                <li>MP3 - Standard Quality (128 kbps)</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-medium text-gray-800 mb-2">Video</h3>
              <ul className="text-gray-600 space-y-1">
                <li>MP4 - Full HD (1080p)</li>
                <li>MP4 - HD (720p)</li>
                <li>MP4 - SD (480p)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}