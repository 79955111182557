export function extractVideoId(url: string): string | null {
  try {
    // First decode the URL in case it's encoded
    const decodedUrl = decodeURIComponent(url);
    return extractVideoIdFromYouTubeUrl(decodedUrl);
  } catch {
    return null;
  }
}

function extractVideoIdFromYouTubeUrl(url: string): string | null {
  try {
    const urlObj = new URL(url);
    
    // Handle youtube.com URLs
    if (urlObj.hostname.includes('youtube.com')) {
      // Handle both watch?v= and shorts/ formats
      const videoId = urlObj.searchParams.get('v') || urlObj.pathname.split('/shorts/')[1];
      return videoId || null;
    } 
    // Handle youtu.be URLs
    else if (urlObj.hostname === 'youtu.be') {
      return urlObj.pathname.slice(1);
    }
    return null;
  } catch {
    // Try to extract ID directly from string if URL parsing fails
    const patterns = [
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i,
      /(?:\/shorts\/)([^"&?\/\s]{11})/i
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }
    return null;
  }
}