import { api } from '../http/client';
import { validateVideoFormat } from '../utils/validation';
import type { ProgressCallback, ApiResponse } from '../types';
import { ValidationError, NetworkError } from '../errors';

export async function downloadVideo(
  videoId: string,
  format: string,
  quality: string,
  onProgress: ProgressCallback
): Promise<string> {
  if (!validateVideoFormat(format, quality)) {
    throw new ValidationError(`Invalid format (${format}) or quality (${quality})`);
  }

  try {
    onProgress('Starting download...', 0);

    // Keep 'p' suffix for video qualities
    const cleanQuality = format.toLowerCase() === 'mp3' 
      ? quality.replace(' kbps', '') 
      : quality.includes('p') ? quality : `${quality}p`;

    const params = new URLSearchParams({
      youtubeID: videoId,
      format: format.toLowerCase(),
      quality: cleanQuality
    });

    const response = await api.get<ApiResponse>(`?${params.toString()}`);
    
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    if (!response.data.downloadUrl) {
      throw new Error('No download URL provided');
    }

    onProgress('Download ready!', 100);
    return response.data.downloadUrl;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new NetworkError('An unexpected error occurred during download');
  }
}