import React, { useState } from 'react';
import { DownloadOption } from '../types/youtube';
import { DownloadButton } from './DownloadButton';
import { downloadVideo } from '../api/services/download';

interface DownloadTableProps {
  videoId: string;
  options: DownloadOption[];
}

export function DownloadTable({ videoId, options }: DownloadTableProps) {
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [status, setStatus] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);

  const handleDownload = async (format: string, quality: string, index: number) => {
    setLoadingIndex(index);
    setStatus('Starting download...');
    setProgress(0);
    
    try {
      const downloadUrl = await downloadVideo(
        videoId,
        format,
        quality,
        (newStatus, newProgress) => {
          setStatus(newStatus);
          setProgress(newProgress);
        }
      );

      // Open download URL in a new tab
      window.open(downloadUrl, '_blank', 'noopener,noreferrer');

    } catch (error) {
      console.error('Download failed:', error);
      setStatus(error instanceof Error ? error.message : 'Download failed');
    } finally {
      setTimeout(() => {
        setLoadingIndex(null);
        setStatus('');
        setProgress(0);
      }, 3000);
    }
  };

  return (
    <div className="w-full space-y-4">
      {options.map((option, index) => (
        <div 
          key={index}
          className="bg-white rounded-xl shadow-sm p-4 hover:shadow-md transition-shadow"
        >
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div className="flex-1">
              <h3 className="text-lg font-medium text-gray-900">
                {option.format.toUpperCase()} - {option.quality}
              </h3>
              <p className="text-sm text-gray-500">
                Codec: {option.codec}
              </p>
              {loadingIndex === index && status && (
                <p className="text-sm text-gray-600 mt-1">{status}</p>
              )}
            </div>
            <div className="w-full sm:w-48">
              <DownloadButton
                onClick={() => handleDownload(
                  option.format,
                  option.quality,
                  index
                )}
                label="Download"
                isLoading={loadingIndex === index}
                disabled={loadingIndex !== null && loadingIndex !== index}
                progress={loadingIndex === index ? progress : undefined}
                format={option.format as 'mp3' | 'mp4'}
                quality={option.quality}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}