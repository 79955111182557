export class APIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'APIError';
  }
}

export class AuthorizationError extends APIError {
  constructor(message = 'Authorization failed. Please try again.') {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export class ValidationError extends APIError {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
  }
}

export class ConversionError extends APIError {
  constructor(message: string) {
    super(message);
    this.name = 'ConversionError';
  }
}

export class NetworkError extends APIError {
  constructor(message = 'Network error. Please check your connection.') {
    super(message);
    this.name = 'NetworkError';
  }
}

export class TimeoutError extends APIError {
  constructor(message = 'Request timed out. Please try again.') {
    super(message);
    this.name = 'TimeoutError';
  }
}