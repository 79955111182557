import { useState, useEffect } from 'react';
import { extractVideoId } from '../utils/youtube';

interface UseYouTubeUrlResult {
  videoId: string | null;
  error: string;
  isWidget: boolean;
}

export function useYouTubeUrl(): UseYouTubeUrlResult {
  const [videoId, setVideoId] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const [isWidget, setIsWidget] = useState<boolean>(false);

  useEffect(() => {
    try {
      const path = window.location.pathname;
      const search = window.location.search;
      let videoUrl = '';

      // Check if we're in widget mode
      if (path.startsWith('/widget/')) {
        setIsWidget(true);
        // Get everything after /widget/ and decode it twice to handle double encoding
        videoUrl = decodeURIComponent(decodeURIComponent(path.slice(7)));
      } else if (path === '/widget') {
        setIsWidget(true);
        const params = new URLSearchParams(search);
        videoUrl = params.get('url') || '';
      } else {
        setIsWidget(false);
        return; // Return early if we're on the search page
      }

      if (!videoUrl) {
        setError('No URL provided');
        return;
      }

      const extractedId = extractVideoId(videoUrl);
      if (extractedId) {
        setVideoId(extractedId);
        setError('');
      } else {
        setError('Invalid YouTube URL');
      }
    } catch (err) {
      setError('Invalid URL format');
    }
  }, []);

  return { videoId, error, isWidget };
}