import React from 'react';

interface FormatTabsProps {
  activeFormat: string;
  onFormatChange: (format: string) => void;
}

export function FormatTabs({ activeFormat, onFormatChange }: FormatTabsProps) {
  return (
    <div className="flex gap-4 mb-6">
      {['MP3', 'MP4'].map((format) => (
        <button
          key={format}
          onClick={() => onFormatChange(format)}
          className={`px-6 py-2 rounded-full text-sm font-medium transition-colors ${
            activeFormat === format
              ? 'bg-purple-600 text-white'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          {format}
        </button>
      ))}
    </div>
  );
}