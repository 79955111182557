import axios from 'axios';
import { API_CONFIG } from '../config/constants';
import { NetworkError, TimeoutError } from '../errors';

export const api = axios.create({
  baseURL: API_CONFIG.baseURL,
  timeout: API_CONFIG.timeout,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      if (error.code === 'ECONNABORTED') {
        throw new TimeoutError('Request timed out. Please try again.');
      }
      throw new NetworkError('Connection failed. Please check your internet connection.');
    }

    const message = error.response.data?.error || 'An error occurred while processing your request.';
    throw new NetworkError(message);
  }
);