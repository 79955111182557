import React from 'react';

interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-lg">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
        <p className="text-gray-700">{message}</p>
        <p className="text-gray-500 mt-4">
          Usage: /widget/https://www.youtube.com/watch?v=VIDEO_ID
        </p>
        <p className="text-gray-500">
          Supported formats:
        </p>
        <ul className="list-disc list-inside text-gray-500 mt-2">
          <li>youtube.com/watch?v=ID</li>
          <li>youtu.be/ID</li>
          <li>youtube.com/shorts/ID</li>
        </ul>
      </div>
    </div>
  );
}