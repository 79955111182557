import React from 'react';
import { YouTubeWidget } from './components/YouTubeWidget';
import { ErrorMessage } from './components/ErrorMessage';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchPage } from './components/SearchPage';
import { useYouTubeUrl } from './hooks/useYouTubeUrl';

export default function App() {
  const { videoId, error, isWidget } = useYouTubeUrl();

  // Show search page if not in widget mode
  if (!isWidget) {
    return <SearchPage />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!videoId) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <YouTubeWidget videoId={videoId} />
    </div>
  );
}