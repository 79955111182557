import React, { useState } from 'react';
import { FormatTabs } from './FormatTabs';
import { DownloadTable } from './DownloadTable';
import { DownloadOption } from '../types/youtube';

interface YouTubeWidgetProps {
  videoId: string;
}

export function YouTubeWidget({ videoId }: YouTubeWidgetProps) {
  const [activeFormat, setActiveFormat] = useState('MP3');

  const mp3Options: DownloadOption[] = [
    { quality: '320 kbps', format: 'mp3', codec: 'lame' },
    { quality: '256 kbps', format: 'mp3', codec: 'lame' },
    { quality: '192 kbps', format: 'mp3', codec: 'lame' },
    { quality: '128 kbps', format: 'mp3', codec: 'lame' },
    { quality: '64 kbps', format: 'mp3', codec: 'lame' },
  ];

  const mp4Options: DownloadOption[] = [
    { quality: '1080p', format: 'mp4', codec: 'h264' },
    { quality: '720p', format: 'mp4', codec: 'h264' },
    { quality: '480p', format: 'mp4', codec: 'h264' },
    { quality: '360p', format: 'mp4', codec: 'h264' },
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-xl shadow-lg">
      <FormatTabs activeFormat={activeFormat} onFormatChange={setActiveFormat} />
      <DownloadTable
        videoId={videoId}
        options={activeFormat === 'MP3' ? mp3Options : mp4Options}
      />
    </div>
  );
}